<template>
<div>
 <v-scale-screen :fullScreen="false" width="1920" height="1080">

  <!-- 预览1个视频 -->
  <div style="width: 100%;height: 100%;background: rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index: 99999!important" v-if="showOne" >
    <div style="position: absolute;top:250px;left:500px;z-index: 999;height: 600px;width: 50%;margin: 0 auto;">
      <div style="height: 42px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
        <img style="height: 42px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
        <span style="line-height: 42px;color: white;brightness(200%);font-weight: 800;font-size: 25px;font-family:">2023年9月10日会议视频</span>
        <span style="line-height: 40px;float: right;color: white;margin-right: 10px;font-size: 25px;" @click="close">×</span>
      </div>
      <div style="height: 5px;width: 100%;"></div>
      <div style="height: 500px;width: 100%;padding-top: 25px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
        <video autoplay muted loop controls class="video" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/kjxn/1.mp4"></video>
      </div>
    </div>
  </div>

  <!-- 预览1个视频 -->
  <div style="width: 100%;height: 100%;background: rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index: 99999!important" v-if="showTwo" >
    <div style="position: absolute;top:250px;left:500px;z-index: 999;height: 600px;width: 50%;margin: 0 auto;" >
      <div style="height: 42px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
        <img style="height: 42px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
        <span style="line-height: 42px;color: white;brightness(200%);font-weight: 800;font-size: 25px;font-family:">2023年9月11日会议视频</span>
        <span style="line-height: 40px;float: right;color: white;margin-right: 10px;font-size: 25px;" @click="close">×</span>
      </div>
      <div style="height: 5px;width: 100%;"></div>
      <div style="height: 500px;width: 100%;padding-top: 25px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);;">
        <video autoplay muted loop controls class="video" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/kjxn/2.mp4"></video>
      </div>
    </div>
  </div>

 <!-- 视频列表 -->
 <div style="width: 100%;height: 100%;background: rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index: 99999!important" v-if="showList" >
  <div style="position: absolute;top:250px;left:500px;z-index: 999;height: 600px;width: 50%;margin: 0 auto;" >
    <div style="height: 42px; background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
      <img style="height: 42px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
      <span style="line-height: 42px;color: white;brightness(200%);font-weight: 800;font-size: 25px;font-family:">视频列表</span>
      <span style="line-height: 40px;float: right;color: white;margin-right: 10px;font-size: 25px;" @click="close">×</span>
    </div>
    <div style="height: 5px;width: 100%;"></div>
    <div style="height: 450px; background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
      <div style="height: 100px;width: 200px;margin: 15px;float: left;">
        <video autoplay muted loop controls class="video" style="border-radius: 35px;" @click="openVideoPopup" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/kjxn/1.mp4"></video>
        <p style="color: white;margin: 0 auto;margin-left: 15px;">2023年9月10日会议视频</p>
      </div>
      <div style="height: 100px;width: 200px;margin: 15px;float: left;">
        <video autoplay muted loop controls class="video" style="border-radius: 35px;" @click="openVideoPopup2" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/kjxn/2.mp4"></video>
        <p style="color: white;margin: 0 auto;margin-left: 15px;">2023年9月11日会议视频</p>
      </div>
    </div>
  </div>
 </div>
  

  <div class="newScreen">
        <div class="videobg">
          <div class="overlay"></div>
          <video autoplay muted loop objectFit="cover" style="width:100%;height:100%;object-fit: cover">
              <source src="@/assets/img/bg.mp4" type="video/mp4">
          </video>
        </div>

      <!-- 顶部 -->
        <div class="top">
          <div class="top_title">在线课堂</div>
          <div class="top_title_people">
            <div class="top">
              <img class="left" src="@/assets/img/tel_phone.png" alt="">
              <div class="right">
                服务终端在线人数
              </div>
            </div>
            <div class="bottom">{{$store.state.onlinePoeple}}</div>
          </div>
        </div>
        
        <div class="cont">
            <!-- 左中 -->
        <div class="contLeft">
          <div class="nav_left">
            <div class="flex_space flex_align" style="margin-left: 46px;width: 503px;">
              <div class="nav_left_item" v-for="(item,index) in leftList" :key="index" @click="showMode(item,index)">
                <div class="nav_left_img"><img  :src="item.icon" style="width:100%;height:100%"/></div>
                <div class="nav_left_name">{{item.name}}</div>
                <div class="nav_left_num">{{ item.num || 0 }}</div>
              </div>
              <img src='@/assets/img/return.png'  style="height:70px;width:70px;margin-left:8px;z-index:100;display:block" @click="goHome()"/>
            </div>
          </div>
          <div class="nsLeft">
            <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">学习热度</div>
                </div>
                <div class="line"></div>
                <div class="panel" style="height: 252px;margin-bottom: 30px;width: 350px;">
                  <div ref="readline" style="width:100%;height:200px;margin-top: 20px;margin-left: 20px;"></div>
                </div>
            </div>
            <div class="left-center" style="margin-top:25px">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">课堂视频</div>
                  <img  @click="videoList" class="lc_right" src="./assets/img/rarrow.png"/>
                </div>
                <div class="line"></div>
                <div class="panel">
                  <div style="width: 320px;margin: 20px;">
                    <div style="height: 200px;">
                      <video autoplay muted loop controls class="video" @click="openVideoPopup" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/kjxn/1.mp4"></video>
                    </div>
                    <p>2023年9月10日会议视频</p>
                    <div style="height: 200px;margin-top: 30px;">
                      <video autoplay muted loop controls class="video" @click="openVideoPopup2" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/kjxn/2.mp4"></video>
                    </div>
                    <p>2023年9月11日会议视频</p>
                  </div>
                </div>
            </div>

          </div>
        </div>

        <div class="contCenter">
          <div class="nscenter">
            <!-- 地图 -->
            <div class="mapCss mapCity">
              <div ref="mapBox" style="width:100%;height:100%"> </div>
            </div>
            <div class="center_bottom" style="margin-top: -40px;">
              <div class="left-center center_bleft">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">读物排行 TOP10</div>
                </div>
                <div class="line"></div>
                <div class="panel" style="height: 400px;">
                  <div ref="industry" style="width: 100%;height: 360px;margin: 10px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

            <!-- 右 -->
        <div class="contRight">
          <div class="nsright">
            <div class="left-center">
              <div class="flex_space flex_center_align">
                <div class="lc_title">学习排行</div>
              </div>
              <div class="line"></div>
                <div class="panel" style="height: 837px;">
                  <div style="background: linear-gradient(90deg, #182D68 0%, rgba(13, 24, 54, 0) 100%);width: 90%;margin: 10px auto;" v-for="(item,index) in groupList" :key="index">
                    <el-row style="display: flex; justify-content: space-between;">
                      <div style="width:10%;line-height: 45px;height: 45px;color: white;brightness(200%);float: left;font-weight: 800;font-family:'Source Han Sans CN'"><img style="height: 35px;margin-top: 5px;" :src="imgList[index].icon"/></div>
                      <div style="width:35%;line-height: 45px;height: 45px;color: white;brightness(200%);float: left;font-weight: 800;font-family:'Source Han Sans CN'">{{item.areaName }}</div>
                      <div style="width:35%;line-height: 45px;height: 45px;color: white;brightness(200%);float: left;font-weight: 800;font-family:'Source Han Sans CN'">{{item.userFullName }}</div>
                      <div style="width:20%;line-height: 45px;height: 45px;color: white;brightness(200%);float: right;text-align: right;font-size: 14px;font-family:'Source Han Sans CN'">阅读{{ item.days }}天</div>
                    </el-row>
                  </div>
              </div>
            </div>
          </div>
        </div>  
    </div>
  </div>
  <newMode ref="newMode"></newMode>
 </v-scale-screen>
</div>
</template>
<script>
import newMode from '@/components/newMode.vue'
import { mapOption, barOptions } from './utils/options.js'
import { mapdata, geoCoordMap, coordinates } from './utils/mapCenterPoint'
import { vipCarDateList, vipCarList, getIndexStatistics, postLogin, getDangIndex, getRead, getLineClass, articleInfoList, getBankList, learnRanking, getBookRankings, searchReadNums } from '@/api/api.js'
import * as echarts from 'echarts'
const mapData = require('./utils/newcun.json')

export default {
  components: {
    newMode
  },
  data() {
    return {
      leftList: [
        {
          name: '民众心声',
          icon: require('./assets/img/imz.png'),
          type: '3',
          ptype: 'voxPop',
          num: 0
        },
        {
          name: '全民广场',
          icon: require('./assets/img/iqm.png'),
          type: '2',
          ptype: 'nationalSquare',
          num: 0
        },
        {
          name: '在线课堂',
          icon: require('./assets/img/isk.png'),
          type: '1',
          ptype: 'readBook',
          num: 0
        },
        {
          name: '道德银行',
          icon: require('./assets/img/ibank.png'),
          type: '4',
          ptype: 'releaseReq',
          num: 0
        },
        {
          name: '贵客到访',
          icon: require('./assets/img/car.png'),
          type: '5',
          ptype: 'monitor',
          num: 0
        }
      ],
      zpajList: [{ fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }],
      total: { familyNum: 0, residentNum: 0, adminUserNum: 0, oftenNum: 0, foreignNum: 0, flowNum: 0, rangerNum: 0, impatientNum: 0 },
      partyNums: {},
      memberCount: {},
      groupList: [],
      baseform: {},
      imgList: [
        { index: 0, icon: require('./assets/img/num1.png') },
        { index: 1, icon: require('./assets/img/num2.png') },
        { index: 2, icon: require('./assets/img/num3.png') },
        { index: 3, icon: require('./assets/img/num4.png') },
        { index: 4, icon: require('./assets/img/num5.png') },
        { index: 5, icon: require('./assets/img/num6.png') },
        { index: 6, icon: require('./assets/img/num7.png') },
        { index: 7, icon: require('./assets/img/num8.png') },
        { index: 8, icon: require('./assets/img/num9.png') },
        { index: 9, icon: require('./assets/img/num10.png') },
        { index: 10, icon: require('./assets/img/num11.png') },
        { index: 11, icon: require('./assets/img/num12.png') },
        { index: 12, icon: require('./assets/img/num13.png') },
        { index: 13, icon: require('./assets/img/num14.png') },
        { index: 14, icon: require('./assets/img/num15.png') }
      ],
      showOne: false,
      showTwo: false,
      showList: false,
      oneName: '',
      twoName: '',
      nerwstimer: ''
    }
  },
  methods: {
    goHome() {
      this.$router.replace('/integrated')
    },
    initMap() {
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value * 2)
            })
          }
        }
        return res
      }

      echarts.dispose(this.$refs.mapBox)
      var myChart = echarts.init(this.$refs.mapBox)

      var option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0, 153, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function(params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            // console.log(name);
            res = "<span style='color:#fff;'>" + name + '</span>'
            return res
          }
        },
        geo: {
          map: 'xiaopingfang',
          aspectScale: 1, //长宽比
          zoom: 1.15,
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: '#00FFFF', // 地图颜色
              // borderWidth: 8, // 边框的宽度
              shadowColor: '#0099ff', //  阴影颜色
              borderColor: '#00FFFF', // 边框颜色
              shadowBlur: 12, // 阴影的模糊大小
              shadowOffsetX: 0, //阴影水平方向上的偏移距离
              shadowOffsetY: 15, // 阴影垂直方向上的偏移距离
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#69c5d8' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#126caf' // 50% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.8, //最小的缩放值
            max: 3 //最大的缩放值
          }
        },
        series: [
          {
            type: 'map',
            map: 'xiaopingfang', //使用
            roam: false,
            aspectScale: 1,
            // geoIndex: 0,
            zoom: 1.15,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },

            itemStyle: {
              normal: {
                areaColor: '#334fa3', //正常背景颜色
                // borderColor: '#4F8DBB',
                borderColor: '#8cD2',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#2AB8FF', //选中颜色
                borderWidth: 0,
                color: 'green',
                show: false
              }
            },
            scaleLimit: {
              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              min: 0.8, //最小的缩放值
              max: 3 //最大的缩放值
            }
          },
          {
            name: 'city',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            data: convertData(mapdata),
            symbolSize: 5,
            showEffectOn: 'render',
            //涟漪特效相关配置
            rippleEffect: {
              brushType: 'stroke',
              period: 1,
              scale: 2 //动画中波纹的最大缩放比例。
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#0098d9', //  阴影颜色
                shadowBlur: 30,
                shadowOffsetX: 0, //阴影水平方向上的偏移距离
                shadowOffsetY: 20, // 阴影垂直方向上的偏移距离
                emphasis: {
                  areaColor: '#2B91B7'
                }
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            polyline: true,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.3,
              symbolSize: 6
            },
            lineStyle: {
              width: 1.5,
              color: '#7ee0ff',
              opacity: 0.5
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coordinates,
            zlevel: 10
          }
        ]
      }

      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, {
      //   renderer: 'svg',
      // })
      // map.clear()
      myChart.setOption(option)
    },
    initChart() {
      this.initMap()
      // 地图绘制
      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, { renderer: 'svg' })
      // map.clear()
      // map.setOption(mapOption)

      getBookRankings().then(res => {
        console.log(res)
        if (res.code == '0') {
          let xarr = []
          let yarr = []
          res.model.map(i => {
            xarr.push(i.bookName)
            yarr.push(i.clickNums)
          })
          barOptions.xAxis.data = xarr
          barOptions.yAxis.axisLine.lineStyle.color = '#fff'
          barOptions.xAxis.axisLabel.rotate = 45
          barOptions.grid.x = 40

          barOptions.grid.bottom = 100
          barOptions.yAxis.name = '(次)'
          barOptions.series[0] = {
            type: 'bar',
            showBackground: false,
            barMaxWidth: 16, // 每一个都要设置
            barMinWidth: 5, // 每一个都要设置
            itemStyle: {
              barBorderRadius: [6, 6, 6, 6],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#FFFFFF' }, { offset: 1, color: '#0063B8' }]),
              emphasis: {
                label: {
                  show: true,
                  position: 'top',
                  color: '#fff'
                }
              }
            },
            data: yarr
          }
          let chart = echarts.init(this.$refs.industry, null, { renderer: 'svg' })
          chart.clear()
          chart.setOption(barOptions)
        }
      })
    },
    drowCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#F9B814', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['常住人口', '外来人口', '流动人口']
        },
        series: [
          {
            name: '人口',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [{ value: data.oftenNum, name: '常住人口' }, { value: data.foreignNum, name: '外来人口' }, { value: data.flowNum, name: '流动人口' }]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.circle, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    getgrids() {
      getIndexStatistics().then(res => {
        if (res.code == 0) {
          this.total = res.items[0]
          this.$nextTick(() => {
            this.drowCircle(res.items[0])
          })
        }
      })
    },
    showMode(item, index) {
      console.log(item)
      if (['3', '2'].includes(item.type)) {
        this.searchArticles(item.type, item)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      } else if (item.type == '5') {
        this.vipCarList(item, index)
      }
    },
    vipCarList(item, index) {
      vipCarDateList().then(res => {
        if (res.code == '0') {
          console.log('res', res)
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchArticles(type, data) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    // 登录
    submitForm(formName) {
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            this.resetSetItem('userInfo', JSON.stringify(res.model))
            sessionStorage.setItem('token', res.model.tokenValue)
            this.searchDetails(res.model.regionId)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchDetails(regionId) {
      getDangIndex({ villageId: regionId }).then(res => {
        if (res.code == 0) {
          // this.totalNums = res.model.memberCount
          // this.$nextTick(() => {
          //   this.drowFunction(res.model)
          // })
        }
      })
    },
    // 学习热度线图
    searchRead() {
      getRead().then(res => {
        if (res.code == '0') {
          let xarr = []
          let yarr = []
          res.model.map(i => {
            xarr.push(i.monthValue + '月')
            yarr.push(i.browseNums)
          })

          let lioption = {
            title: { text: '' },
            grid: {
              top: '10',
              left: '30',
              bottom: '20'
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: xarr,
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#3a416b'
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#a1b3e5',
                  fontSize: 8
                }
              }
            },
            yAxis: {
              type: 'value',
              labelLine: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#a1b3e5'
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#a1b3e5',
                  fontSize: 8
                }
              }
            },
            series: [
              {
                data: yarr,
                type: 'line',
                smooth: 0.6,
                symbol: 'none',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      // 渐变颜色
                      {
                        offset: 0,
                        color: '#334fa3'
                      },
                      {
                        offset: 1,
                        color: 'rgba(51,79,163, 0)'
                      }
                    ],
                    global: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#4c78ed', //改变折线点的颜色
                    lineStyle: {
                      color: '#4c78ed' //改变折线颜色
                    }
                  }
                }
              }
            ]
          }

          let readline = echarts.init(this.$refs.readline, null, { renderer: 'svg' })
          readline.clear()
          readline.setOption(lioption)
        }
      })
    },

    showMessage(content) {
      if (content) {
        let item = this.leftList.filter(item => {
          return item.type == content.type
        })[0]
        let index = this.leftList.findIndex(item => item.type == content.type)

        setTimeout(() => {
          item.num = item.num + 1
          this.$set(this.leftList, index, item)
        }, 10000) // 3秒后隐藏消息
      }
    },
    searchNews() {
      this.nerwstimer = setInterval(() => {
        this.getReadnums()
      }, 10000) // 滚动速度
    },

    // 学习排行
    learnRanking() {
      learnRanking().then(res => {
        this.groupList = res.model.slice(0, 15)
      })
    },
    // 视频弹窗
    openVideoPopup() {
      this.showTwo = false
      this.showList = false
      this.showOne = true
    },
    // 视频弹窗
    openVideoPopup2() {
      this.showOne = false
      this.showList = false
      this.showTwo = true
    },
    // 关闭预览
    close() {
      this.showOne = false
      this.showTwo = false
      this.showList = false
    },
    videoList() {
      this.showList = true
    },
    getReadnums() {
      searchReadNums().then(res => {
        if (res.code == '0') {
          this.leftList.map(i => {
            if (i.ptype == 'voxPop') i.num = res.model.voxPopNum
            if (i.ptype == 'nationalSquare') i.num = res.model.nationalSquareNum
            if (i.ptype == 'readBook') i.num = res.model.onlineReadNum
            if (i.ptype == 'releaseReq') i.num = res.model.releaseReqNum
            //贵客到访
            if (i.ptype == 'monitor') i.num = res.model.monitorNum
          })
          //终端在线人数
          this.$store.state.onlinePoeple = res.model.onlineNums
        }
      })
    }
  },
  mounted() {
    this.submitForm()
    this.$nextTick(() => {
      this.searchNews()
      this.getReadnums()
      this.learnRanking()
      this.initChart()
      this.getgrids()
      this.searchRead()
    })
  },
  destroyed() {
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  }
}
</script>
<style lang="scss" scope>
@import '@/common/css/inter';
.video {
  width: 100%;
  height: 100%;
}
</style>